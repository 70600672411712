// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
    font-family: montserrat, sans-serif;
    line-height: 1.15;
    font-weight: 400;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    color: #212529;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  // for I.E.
  @-ms-viewport {
    width: device-width;
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  main {
    width: 100vw;
  }

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  hr {
    box-sizing: content-box;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  abbr[title] {
    text-decoration: dotted underline;
    cursor: help;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  dfn {
    font-style: italic;
  }

  b,
  strong {
    font-weight: 700;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -.25em;
  }

  sup {
    top: -.5em;
  }

  a {
    color: #0069AF;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  //a:hover {
  //  color: #0056b3;
  //  text-decoration: underline;
  //}

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  pre,
  code,
  kbd,
  samp {
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }

  figure {
    margin: 0 0 1rem;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
    /*visibility: visible;*/
    max-width: unset;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
    font-size: 90%;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button, .button, .btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
    border-radius: .25rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 1.3rem 3rem;
    color: #fff;
    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.2px;
    overflow: hidden;
    cursor: pointer;
    background-color: #0069b4;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
  }

  button:focus {
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }



  /*************************************************************************************************************/
  /****                                            END RESET                                                ****/
  /*************************************************************************************************************/

  .wrapper{
    overflow-x: hidden;
    overflow-y: auto;
  }

  h1, .h1 {
    font-size: 2.2rem;
    line-height: 1.3;
    color: #2A333C;
    font-weight: 500;
    margin-top: .6rem;
    margin-bottom: 1.9rem
  }

  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 1.6rem;
    }
  }


  .section-heading p{
    color: #2A333C;
    font-size: 0.95rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.5rem;
    letter-spacing: .03rem;
    font-weight: 500;
    margin: 0 0 0.6rem 0;
    color: #2E2E2D;
  }

  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    h2 {font-weight: 600;}
  }
  h3 {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0 0 1rem 0;
    padding: 0.6rem 0;
    color: #2A333C;
  }

  h4 {
    font-size: 1.3rem;
    line-height: 1.5;
    letter-spacing: 0.04rem;
    font-weight: 500;
    margin: 0;
    padding: 0.6em 0;
  }



  p.intro{
    font-size: 1.3rem;
    color: #2E2E2D;
  }

  .p,
  p, li {
    line-height: 1.55;
    font-size: 1rem;
    font-weight: 400;
    font-family: montserrat, sans-serif;
    color: #2E2E2D;
  }

  p.quote{
    font-weight: 600;
    font-style: italic;
    font-size: 1.2rem;
    text-align: center;
    color: #2A333C;

  }
  a{
    color: #0069AF;
    text-decoration: none;
    background-color: transparent;
  }

  .strong{
    letter-spacing: .08rem;
    font-weight: 500;
  }
  .small, .small p, p.small, .small tr, .small td, .small a{font-size:97%;}
  .smaller, .smaller p, .smaller tr, .smaller td {font-size:80%;}
  .bold, .bold p, .bold tr, .bold td{font-weight:bold;}

  .text-center{text-align: center;}
  @media (min-width: 768px) {
    .text-left-md {text-align: left;}
  }
  @media (min-width: 768px) {
    .text-right-md {text-align: right;}
  }
`;

export default GlobalStyle;
