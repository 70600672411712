import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import Seo from "../components/seo"
import "../styles/global-style.css"

import Header from "../components/header/header"
import Footer from "../components/footer"

import Wrapper from '../components/Wrapper';
import InternalLayout from '../layouts/internal'
import {
  Main
} from '../styles/shared';


const ImageTitle = styled.div`
  width: 100%;
  height: 20rem;
  position: relative;
  background-color: #5e5e5e;
  margin-bottom: 2rem;
  
  > h1{
    position: absolute;
    top: calc(50% - 2rem);
    margin: auto;
    width: 100%;
    text-align: center;
    z-index: 300;
  }
`

const BackgroundImage =  styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
  opacity: .5;
`

export const serviceWrapper = styled.article`
  position: relative;
  z-index: 50;

  &.no-image {
    .service-full-content {
      padding-top: 0;
    }

    .service-full-content:before,
    .service-full-content:after {
      display: none;
    }
  }
`;

export const serviceFullHeader = styled.header`
  margin: 0 auto;
  padding: 6vw 3vw 3vw;
  max-width: 1040px;
  text-align: center;

  @media (max-width: 500px) {
    padding: 14vw 3vw 10vw;
  }
`;

export const serviceFullTitle = styled.h1`
  margin: 0;
  font-size: 2.4rem;
  font-weight: 600;
  color: #2E2E2D;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;


const PageTemplate = props => {
  const service = props.data.markdownRemark;

  return (
    <InternalLayout className="service-template">
      <Seo title={service.frontmatter.title || this.props.site.siteMetadata.title} />
      <Wrapper>
        <Header />
        <ImageTitle>
          <h1 css={{color: `#fff`}}>
            {service.frontmatter.title}
          </h1>
          <BackgroundImage
              alt={service.frontmatter.title}
              style={{ height: '100%' }}
              image={service.frontmatter.serviceImage.childImageSharp.gatsbyImageData}
          />
        </ImageTitle>
        <Main id="site-main" className="site-main">
            {/* TODO: no-image css tag? */}
            <div className={!service.frontmatter.image ? "no-image" : ""}>
              <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: service.html }}
              />
            </div>
        </Main>

        {/* Links to Previous/Next services */}
        {/*<aside className="read-next" css={outer}>*/}
        {/*  <div css={inner}>*/}
        {/*    <ReadNextFeed>*/}
        {/*      /!*{props.data.relatedservices && (*!/*/}
        {/*      /!*  <ReadNextCard tags={service.frontmatter.tags} relatedservices={props.data.relatedservices} />*!/*/}
        {/*      /!*)}*!/*/}

        {/*      {props.pageContext.prev && <serviceCard service={props.pageContext.prev} />}*/}
        {/*      {props.pageContext.next && <serviceCard service={props.pageContext.next} />}*/}
        {/*    </ReadNextFeed>*/}
        {/*  </div>*/}
        {/*</aside>*/}
        <Footer />
      </Wrapper>
    </InternalLayout>
  );
};

export default PageTemplate;

export const query = graphql`query ($slug: String) {
  logo: file(relativePath: {eq: "images/ghost-logo.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FIXED)
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    frontmatter {
      serviceImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      title
      author
      layout
    }
    html
  }
  site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
}
`;
