import GlobalStyle from '../styles/globalStyles';
import * as React from 'react';
import Helmet from 'react-helmet';

// @ts-ignore
import favicon from '../../src/favicon.png';

interface InternalProps {
  className?: string;
}

const InternalLayout: React.FC<InternalProps> = props => {
  return (
    <div className={props.className}>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <GlobalStyle />
      {props.children}
    </div>
  );
};

export default InternalLayout;
