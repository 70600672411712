import { css } from "styled-components"
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image"

export const outer = css`
  position: relative;
  padding: 0 4vw;
`

// Centered content container blocks
export const inner = css`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`

export const Inner = styled.div`
  margin: 0 auto;
  max-width: 1040px;
  width: 100%;
`

export const SiteMain = css`
  z-index: 100;
  flex-grow: 1;
`

export const SiteTitle = styled.h1`
  z-index: 10;
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
`

export const SiteDescription = styled.h2`
  z-index: 10;
  margin: 0;
  padding: 5px 0;
  font-size: 2.2rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;
`

export const Feed = styled.div`
  /* PostFeed */
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;

  /* PostFeedRaise */
  @media (min-width: 900px) {
    margin-top: 2rem;
    padding-top: 0;
  }
`

export const PostFeed = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding: 40px 0 0 0;
`

export const PostFeedRaise = css`
  @media (min-width: 900px) {
    margin-top: 2rem;
    padding-top: 0;
  }
`

export const SocialLink = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: #fff;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }

  svg {
    height: 1.8rem;
    fill: #fff;
  }
`

export const Main = styled.main`
  /* SiteMain */
  z-index: 100;
  flex-grow: 1;
  /* outer */
  position: relative;
  padding: 0 4vw;
`

export const SiteHeaderStyles = css`
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 10;
  display: block;
  height: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }
  @media (max-width: 700px) {
    padding-right: 0;
    padding-left: 0;
  }
`



// -------------Author content about the author sits under the main header -----------
export const AuthorHeader = css`
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  /* background: #2A333C no-repeat center center; */
  background: #2A333C no-repeat center center;
  background-size: cover;
`

export const AuthorHeaderContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  /* padding: 2rem 0vw 4rem; //custom */
  /* min-height: 200px; //custom */
  text-align: center;
  margin-top:3rem;

  @media (min-width: 768px){
    display: flex;
  }
`

export const AuthorHeaderStyles = css`
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 10;
  display: block;
  height: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0, 0, 0, 0.18);
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  }
  @media (max-width: 700px) {
    padding-right: 0;
    padding-left: 0;
  }
`

export const AuthorProfileImage = styled(GatsbyImage)`
  display: flex;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: #929FAD;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 1rem;
  margin-right: 15px;

`
export const AuthorProfileImageMedium = css`
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
`

export const AuthorLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: 768px){
    width: 20%;
    margin-bottom: 0;
  }

  a, a h1{
    color: rgb(59, 71, 77);
  }
`

export const AuthorRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-left: 2rem;
  text-align: left;

  @media (min-width: 768px){
    width: 75%;
  }
`

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  color: #929FAD;

  li{
    padding: 0;
    a{
      font-size: inherit;
      margin: 0;
      color: inherit;

      :hover{
        color: #3eb0ef;
      }
    }
  }
`


export const HiddenMobile = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

export const DesktopTwoColumns = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    
    @media (min-width: 768px){
        flex-direction: row;
       
       > div{
        width: 50%;
       } 
        
    }
`

